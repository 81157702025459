import * as actionTypes from './../ActionTypes';
import  Axios  from './../../config/axios';

export const GeneratePasswordRequestStart = ()=> {
    return {
        type: actionTypes.GENERATE_PASSWORD_REQUEST
    }
}

export const GeneratePasswordRequestSuccess = (res)=> {
    return {
        type : actionTypes.GENERATE_PASSWORD_REQUEST_SUCCESS,
        data : res
    }
}
export const GeneratePasswordRequestFail = (error)=> {
    return {
        type : actionTypes.GENERATE_PASSWORD_REQUEST_FAIL,
        data: error.response
        
    }
}

export const GeneratePasswordRequest = (data)=> {
    return dispatch => {
       dispatch( GeneratePasswordRequestStart() ) ;
           
         let FormData={};
        FormData['token'] = data.token
        FormData['password'] = data.password
        FormData['password_confirmation'] = data.confirmPassword
        
       return Axios.post('/reset-temp-password', FormData)
       .then ( res => {
           return dispatch ( GeneratePasswordRequestSuccess( res ) )
       } ).catch( error => {
           return dispatch ( GeneratePasswordRequestFail (error) )
       } )
    }
}

export default GeneratePasswordRequest;