import React, { Component } from 'react';
import { Container, Row, Col, Jumbotron, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import BannerStyle from './bannerStyle.scss';

import heroImg from './../../assets/img/dashboard2.png';
import mobileHeroImg from './../../assets/img/mobile-hero-img.png';
import videoUrlMp4 from '../../assets/ParkEngage_Touchless_Parking.mp4'
import videoUrlWemb from '../../assets/ParkEngage_Touchless.webm'
import thumbnail from '../../assets/img/thumbnailnew.jpg'
class BannerComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLinkCopyModalOpen: false,
            CopiedText: ''
        }
    }


    handleLinkCopyModal = () => {
        this.setState({ isLinkCopyModalOpen: this.state.isLinkCopyModalOpen === false ? true : false })
    }

    copyToClipboard() {
        var copyText = document.getElementById("copyInput")
        copyText.select()
        copyText.setSelectionRange(0, 99999)
        document.execCommand("copy")
        this.setState({ CopiedText: copyText.value })
    }

    render() {
        const { CopiedText } = this.state
        return (
            <div className="hero-img">
                <Jumbotron fluid className=" bgImage">
                    <Container>
                        <Row className="justify-content-center">
                            <Col className="text-center">
                                <h1 className="text-uppercase text-dark">The Next Generation of<br /><span>Touchless Parking</span></h1>
                                <p className="  text-dark font-weight-bold ">The exclusive digital platform for the parking industry.</p>
                                <div className="text-center  hero-baanner">
                                    {/* <p className="text-center hero-baanner-img"><img alt="	The Next Generation of your Customers" className="" src={heroImg} /></p> */}
                                    <p className="text-center hero-baanner-img">
                                        {/* <iframe width="600" height="350" src="https://www.youtube.com/embed/5g1Jroe4FDs" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                                        {/* <iframe width="620" height="350" src="https://www.youtube.com/embed/5g1Jroe4FDs?rel=0&enablejsapi=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen;" allowfullscreen></iframe> */}
                                        <video width="600" poster={thumbnail} controls className='videoImproveCSS'>
                                            <source src={videoUrlWemb} type="video/webm" />
                                            <source src={videoUrlMp4} type="video/mp4" />
                                            Sorry, your browser doesn't support videos.
                                        </video>
                                    </p>
                                    <p className="text-center hero-baanner-img-mob">
                                        {/* <iframe id="ytplayer" type="text/html" width="95%" height="200" src="https://www.youtube.com/embed/5g1Jroe4FDs?rel=0&enablejsapi=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen;" allowfullscreen></iframe> */}
                                        <video width="350" poster={thumbnail} controls className='videoImproveCSS'>
                                            <source src={videoUrlWemb} type="video/webm" />
                                            <source src={videoUrlMp4} type="video/mp4" />
                                            Sorry, your browser doesn't support videos.
                                        </video>
                                    </p>

                                    <Row>
                                        <Col>
                                            <span>
                                                <span onClick={this.handleLinkCopyModal}><i class='fas fa-share'></i> Share</span>
                                                <a href='https://youtu.be/5g1Jroe4FDs' target='_blank' style={{ color: '#000' }}><span className='ml-2'><i className='fab fa-youtube' style={{ color: 'red' }}></i> Watch on youtube</span></a>
                                            </span>
                                        </Col>
                                    </Row>
                                    <p className="text-center pt-3 mb-0"><a href="mailto:info@parkengage.com" className="text-white min-width  btn btn-primary">Contact Us for a Free Trial</a></p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Jumbotron>
                <div>
                    <Modal isOpen={this.state.isLinkCopyModalOpen} toggle={this.handleLinkCopyModal} centered id='modalCopyComp'>
                        <ModalBody style={{ height: '.5rem' , borderBottom : 'none'  , marginBottom : '-1rem' }} className='text-success'><p style={{ fontSize : '13px'}}>{(CopiedText) ? 'Text Copied !' : ''}</p></ModalBody>
                        <ModalBody>
                            <div class="input-group">
                                <input type="text" class="form-control" id='copyInput' value='https://youtu.be/5g1Jroe4FDs' aria-describedby="basic-addon2" />
                                <span class="input-group-text" id="basic-addon2" onClick={(e) => this.copyToClipboard(e, 'https://youtu.be/5g1Jroe4FDs')}><i className="fa fa-copy"></i></span>
                            </div>
                        </ModalBody>
                    </Modal>
                </div>
            </div>
        )
    }

}

export default BannerComponent
