import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import BannerComponent from './bannerComponent';
import { Container, Row, Col, Button  } from 'reactstrap';
import TitleContentSection from './titleContent';
import FrectionlessList from './frictionless-list';
import RequestDemoSection from './requestDemoSection';
import CarouselItems from './CarouselItems';
import InformationComponent from './informationComponent';
import TestimonialsSection from './testimonials';
import SubscriberForm from '../contact-us/subscriber-form';
import NewsList from './newsList';
import Aux from './../Wrapper/Auxiliary';


import manLaptopImg from './assets/man-laptop-img.png';
import manBehindBg from './assets/man-behind-bg.png';


import manWithTab from './assets/man-tab.png'
import behindTab from './assets/improve-customer-engagement.png';

import mpfCar from './assets/parking-man.png'
import mpfRoad from './assets/park.png';

import boeSub from './assets/man-valet.png'
import boeBg from './assets/car-valet.png';




import requestDisplay from './assets/request-display.png';
import {faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



import inventoryIcon from './assets/inventory.png';
import monthlyPaymentIcon from './assets/monthly-payment.png';
import ticketLessIcon from './assets/ticket-less.png';
import onlineReservation from "./assets/online-reserv.png";
import touchlessPermitParking from './assets/monthly-payment-bck.png';
import Promotion from './assets/Promotion.png';

import ServicesImageALPR from './assets/anpr.png';
import ServicesImageRAD from './assets/rad.png';
import ServicesImageWLA from './assets/wla.png';
import ServicesImageTVP from './assets/tvp.png';


class HomeComponent extends Component {
    constructor(props){
        super(props)
        this.elmRef1 = React.createRef();
        this.elmRef2 = React.createRef();
        this.elmRef3 = React.createRef();
        this.elmRef4 = React.createRef();
        this.serviceList = React.createRef();

    }

    scrollToServiceRef = (i) => {
        if(i == 1){
            window.scrollTo({left:0, top:this.elmRef2.current.offsetTop-50, behavior: 'smooth'})
        }else if(i == 2){
            window.scrollTo({left:0, top:this.elmRef3.current.offsetTop-60, behavior: 'smooth'})
        }else if(i == 3){
            window.scrollTo({left:0, top:this.elmRef4.current.offsetTop-60, behavior: 'smooth'})
        }else{
            window.scrollTo({left:0, top:this.elmRef1.current.offsetTop-60, behavior: 'smooth'})
        }
    }

    frectionlessSectionTitle = 'Why go Frictionless?';
    frectionlessSectionDesc = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam';
    frectionlessSectionTitle = 'Why go Frictionless?';
    frectionlessSectionDesc = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam';
    semelessSectionTitle = 'Seamlessly go from deploying to scaling.';
    semelessSectionContent = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,';

    carouselOptions = {
      
        nav: false,
        rewind: false,
        autoplay: false,
        margin:30,
        loop:false,
        responsive:{
            0:{
                items:1,
            },
            600:{
                items:2,
            },
            1000:{
                items:4,
            }
        }
    };

    carouselData = [
        {   serviceId: 4,
            title: 'Touchless Self-Parking',
            description: `Make the entire parking experience smooth for your customer. Use our Touchless parking system whether...`,
            link: '/services/touchless-parking-system',
            linkText: 'Learn more',
            itemImage: ticketLessIcon
        },
        {
            serviceId: 2,   
            title: 'Touchless Valet Parking',
            description: `Our Touchless Valet Service creates a convenient experience for parkers, while streamlining your valet...`,
            link: '/services/valet-parking-system',
            linkText: 'Learn more',
            itemImage: ServicesImageTVP
        },
        {
            serviceId: 3,   
            title: 'Touchless Permit Parking',
            description: `Permit customers are your most loyal and profitable base. Provide personalized service with our Touchle...`,
            link: '/services/permit-parking-system',
            linkText: 'Learn more',
            itemImage: monthlyPaymentIcon
        },
        {
            serviceId: 3,   
            title: 'Touchless Monthly Parking',
            description: `Our Monthly Parking solution provides a seamless and comfortable experience for monthly parkers, while... `,
            link: '/services/monthly-parking-system',
            linkText: 'Learn more',
            itemImage:  touchlessPermitParking
        },
        {
            serviceId: 5,   
            title: 'Online Parking Reservations',
            description: `Offer your regular and prospective patrons the opportunity to make online reservations to park in any ..`,
            link: '/services/online-reservations',
            linkText: 'Learn more',
            itemImage: onlineReservation
        },
        {   serviceId: 1,
            title: 'Aggregator and Channel Management',
            description: `Publish and Manage your Reserve-able Inventory for all your Channel Partners in one common... `,
            link: '/services/parking-inventory-management-system',
            linkText: 'Learn more',
            itemImage: inventoryIcon
        },
        
        {   serviceId: 2,
            
            title: 'Promotions and Loyalty',
            description: `Provide seamless, anxiety-free promotion service to your customers with our Promotion service...`,
            link: '/services/promotion',
            linkText: 'Learn more',
            itemImage: Promotion
        },
       
       
       
       
       
        {
            serviceId: 8,   
            title: 'ALPR Based Parking Enforcement',
            description: `ALPR-based solutions allow parking owners and operators to implement more accurate enforcement... `,
            link: '/services/parking-enforcement-system',
            linkText: 'Learn more',
            itemImage: ServicesImageALPR
        },
        // {
        //     serviceId: 9,   
        //     title: 'Accounts Receivable (A/R) Management',
        //     description: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore rt ..`,
        //     link: '/services/parking-AR-system',
        //     linkText: 'Learn more',
        //     itemImage: ServicesImageARM
        // },
        {
            serviceId: 10,   
            title: 'Reporting and Analytics Dashboards',
            description: `Reporting and Analytics dashboards are essential to enabling parking owners and operators to under...`,
            link: '/services/analytics-system',
            linkText: 'Learn more',
            itemImage: ServicesImageRAD
        },
        {
            serviceId: 11,   
            title: 'White Label Apps',
            description: `White label App for Parking Operators and Parking facilities Owners who want to offer a feature...`,
            link: '/services/white-label-apps',
            linkText: 'Learn more',
            itemImage: ServicesImageWLA
        }

    ]

     frectionlessSectionTitle = 'Why Go Digital?';
     frectionlessSectionDesc ='ParkEngage digital technologies provide parking owners and operators tools to generate new revenue streams, improve customer engagement, create touchless parking experiences, and enhance operational officiencies.';

    //  frectionlessSectionTitle = 'Why go Digital?';
    //  frectionlessSectionDesc ='ParkEngage digital technologies provide parking owners and operators tools to generate new revenue streams, improve customer engagement, create touchless parking experiences, and enhance operational efficiencies.';

    semelessSectionTitle = 'Generate New Revenue Streams';
    semelessSectionContent = 'Gain control of your parking inventory. Embrace the strengths of aggregators and channel partners, and resell your parking inventory on your own terms.';
    
    ImproveCustomerEngagementTitle = 'Improve Customer Engagement';
    ImproveCustomerEngagementContent = 'Attract new customers with innovative promotions that keep you ahead of your competition. Create brand loyalty with long-term, repeat customers.';

    MakeParkingFrictionlessTitle = 'Create Touchless Parking Experiences';
    MakeParkingFrictionlessContent = 'Meet the needs and expectations of your highly mobile and busy customer base. In this digital age, customers want control of their experiences at their fingertips. And they are willing to pay extra. Help them to create a safe, convenient parking experience that meets their unique needs.';

     BoostOperationalEfficienciesTitle = 'Enhance Operational Efficiencies';
     BoostOperationalEfficienciesContent = 'Valets and attendants are the face of your facility – the customer’s first and last experience. Even one negative experience can lose a customer for life. Implement technologies and strategies that offer your customers a convenient and safe parking experience, while increasing valet and attendant productivity.';

    
    semelessSectionBgImage = {
        background: 'url(' + manBehindBg + ')',
        backgroundRepeat: 'no-repeat',
    }

    ImproveCustomerEngagementBgImage = {
        background: 'url(' + behindTab + ')',
        backgroundRepeat: 'no-repeat',
    }
    ImproveCustomerEngagementBgImage = {
        background: 'url(' + behindTab + ')',
        backgroundRepeat: 'no-repeat',
    }
    MakeParkingFrictionlessBgImage = {
        background: 'url(' + mpfRoad + ')',
        backgroundRepeat: 'no-repeat',
    }

    BoostOperationalEfficienciesBgImage = {
        background: 'url(' + boeBg + ')',
        backgroundRepeat: 'no-repeat',
    }

    elmRef = React.createRef();

    clickHandlerToServices = () => {
        window.scrollTo({left:0, top:this.serviceList.current.offsetTop-50, behavior: 'smooth'})
        // let height = 4100;
        // // if(index == 1){
        // //     height = 1775;
        // // }else if(index == 2){
        // //     height = 2481;
        // // }else if(index == 3){
        // //     height = 3100;
        // // }else{
        // //     height = 1060;
        // // }

        // window.scrollTo({
        //     top: height,
        //     left: 0,
        //     behavior: 'smooth'
        // })
    }


    render() {
        return (
            <Aux>
            <div className="content">
                <BannerComponent></BannerComponent>
                {/* <div className="infoBacking responsive  p-4 visible-xs bg-secondry clearfix ">
                    <div className="container">
                    <div className="row  align-items-center justify-content-center">
    <div className="col-12 text-center">
            <p className="p-0 m-0"><h4 className="m-0 p-0">Join us for our upcoming webinar.</h4>The Next Generation of Parking Technology: Solutions to Increase Revenues and Enhance Customer Engagement  <br/>
        Thursday, December 10, 2020  |  2:00 pm EST
       
          </p>
    </div>
    <div className="col text-center mt-2">
      <a className="btn btn-primary btn-outline-primary btn-sm" target="_blank" href="https://zoom.us/webinar/register/WN_J-wvsF75RfufdsD9j7MBcA" >Join Now</a>
    </div>
  </div>

                    </div>

  
 

</div> */}
                {/* <section id="content-section mt-5">
                    <Container className="contentSection">
                        <TitleContentSection title={this.frectionlessSectionTitle} content={this.frectionlessSectionDesc} />
                        <FrectionlessList clicked={this.scrollToServiceRef} ></FrectionlessList>
                    </Container>
                </section> */}
                <section ref={this.serviceList} id="content-section" className="">
                <Container className="contentSection">
                        <div className="row justify-content-center" >
                            <div className="text-center mb-3 mt-5 col-md-8">
                                <h4 className="sectionTitle text-dark font-weight-bold">What We Do</h4>
                              
                            </div>
                        </div>
                    </Container>
                    <Container fluid={true}>
                   <CarouselItems  carouselOptions={this.carouselOptions} carouselData={this.carouselData} ></CarouselItems>
                    </Container>
                        <Container>
                        <p className="text-center hidden-xs pt-4 min-width pb-4"><Link to={"/services"} className="text-primary"> Explore More  </Link></p>
                        <p className="text-center visible-xs py-2 min-width "><Link to={"/services"} className="text-primary"> Explore More  </Link></p>
                    </Container>
                </section>

                {/* <section id="contentSection1" className="bg-secondry contentSection">
                    <Container>
                        <div ref={this.elmRef1}>
                        <TitleContentSection tag="h3" title={this.semelessSectionTitle} content={this.semelessSectionContent} /></div>
                        <Row className=" mb-4 mt-4 justify-content-center">
                            <Col sm="auto" lg="5">
                                <div className="list-img">
                                    <ul>
                                        <li>Reach a wider market and expand your partner ecosystem</li>
                                        <li>Maintain control over your parking assets</li>
                                        <li>Consolidate channel partner management</li>
                                        <li>Maximize revenues through dynamic pricing </li>
                                        <li>Maintain competitive advantage through market pricing</li>
                                      
                                      
                                    </ul>
                                    <p className="pt-4 pb-4 text-center text-md-left">
                                    <Link to="/request-a-demo" className="btn btn-primary min-width btn-outline-primary mr-2 mb-3" >Request Demo </Link>
                                    <Link to="" onClick={()=>this.clickHandlerToServices()} className="btn btn-outline-primary min-width  mb-3 btn-light" > View Products  </Link>
                                    </p>
                                </div>
                            </Col>
                                <Col >
                                <div className="dev-scan-bg" alt={this.semelessSectionTitle+' background'} style={this.semelessSectionBgImage}>
                                    <img  width="80%" alt={this.semelessSectionTitle} data-aos="fade-left" src={manLaptopImg} />
                                </div>
                            </Col>
                        </Row>
                        </Container>
                </section>
                <section id="contentSection2" ref="contentSection1" className=" contentSection hidden-overflow">
                    <Container>
                    <div ref={this.elmRef2}><TitleContentSection tag="h3" title={this.ImproveCustomerEngagementTitle} content={this.ImproveCustomerEngagementContent} /></div>
                        <Row className=" mb-4 mt-4  justify-content-center">
                        <Col  lg="7" className="col-lg order-lg-1 order-2">
                            
                                <div className="dev-scan-bg" alt={this.ImproveCustomerEngagementTitle+' background'}  style={this.ImproveCustomerEngagementBgImage}>
                                    <img width="70%" alt={this.ImproveCustomerEngagementTitle} data-aos="fade-right" src={manWithTab} />
                                </div>
                            </Col>
                            <Col lg="5" sm="auto"  className="col-lg order-1">
                                <div className="list-img">
                                <ul>
                                    <li>Increase your visibility over the competition</li>
                                    <li>Launch promotions that instantly grab customer attention</li>
                                    <li>Optimize promotions and track ongoing customer engagement</li>
                                    <li>Increase visibility on online and mobile platforms</li>
                                    <li>Create long-term repeat customers</li>
                                    <li>Incentivize and create loyal brand ambassadors</li>
                                    
                                </ul>
                            <p className="pt-4 text-center text-md-left">
                            <Link to="/request-a-demo" className="btn btn-primary btn-outline-primary mr-2 mb-3 min-width" >Request Demo </Link>
                            <Link to="" onClick={()=>this.clickHandlerToServices()} className="btn btn-outline-primary mb-3 min-width btn-light" > View Products  </Link> 
                                    </p>
                                </div>
                            </Col>
                               
                        </Row>
                        </Container>
                </section>

                <section id="contentSection3" ref="contentSection2" className=" contentSection bg-secondry">
                    <Container>
                    <div ref={this.elmRef3}><TitleContentSection tag="h3" title={this.MakeParkingFrictionlessTitle} content={this.MakeParkingFrictionlessContent} /></div>
                        <Row className="mb-4 mt-4  justify-content-center">
                            <Col sm="auto" lg="5">
                                <div className="list-img">
                                <ul>
                                    <li>Reduce or remove backup times at gated exits and valet wait times</li>
                                    <li>Eliminate paper tickets with eTickets</li>
                                    <li>Implement efficient and convenient payments through mobile pay</li>
                                    <li>Sell add-on services for customers to purchase and reserve directly from mobile devices</li>
                                    <li>Provide personalized customer service to create brand loyalty</li>
                                 
                                </ul>
                                    <p className="pt-4 text-center text-md-left">
                                    <Link to="/request-a-demo" className="btn btn-primary btn-outline-primary mr-2 min-width mb-3" >Request Demo </Link>
                                    <Link to="" onClick={()=>this.clickHandlerToServices()} className="btn btn-outline-primary min-width mb-3 btn-light" > View Products  </Link>
                                    </p>
                                </div>
                            </Col>
                                <Col sm="auto" lg="7">
                                <div className="dev-scan-bg" alt={this.MakeParkingFrictionlessTitle+' background'} style={this.MakeParkingFrictionlessBgImage}>
                                    <img width="70%" alt={this.MakeParkingFrictionlessTitle} data-aos="fade-left" src={ mpfCar} />
                                </div>
                            </Col>
                        </Row>
                        </Container>
                </section>*/}
{/*                 
                <section id="contentSection4" ref="contentSection3" className="hidden-overflow contentSection"> 
                    <Container>
                    <div ref={this.elmRef4}><TitleContentSection tag="h3" title={this.BoostOperationalEfficienciesTitle} content={this.BoostOperationalEfficienciesContent} /></div>
                        <Row className=" mb-4 mt-4  justify-content-center">
                        <Col  lg="7" className="col-lg order-lg-1 order-2">
                            
                                <div className="dev-scan-bg" alt={this.BoostOperationalEfficienciesTitle+' background'} style={this.BoostOperationalEfficienciesBgImage}>
                                    <img width="90%" alt={this.BoostOperationalEfficienciesTitle} data-aos="fade-right" src={boeSub} />
                                </div>
                            </Col>
                            <Col sm="auto" lg="5" className="col-lg order-1">
                                <div className="list-img">
                                <ul>
                                    <li>Increase safety and social distancing</li>
                                    <li>Improve valet productivity</li>
                                    <li>Reduce labor dependency</li>
                                    <li>Increase facility automation</li>
                                    <li>Enhance staff accountability </li>
                                    <li>Reduce potential for vehicle damage and theft</li>
                                    <li>Maintain a real-time view of operations at your fingertips</li>
                                </ul>
                                <p className="pt-4 text-center text-md-left">
                                        <Link to="/request-a-demo" className="btn btn-primary btn-outline-primary min-width mr-2 mb-3" >Request Demo </Link>
                                        <Link to="" onClick={()=>this.clickHandlerToServices()} className="btn btn-outline-primary min-width mb-3 btn-light" > View Products  </Link>
                                    </p>
                                </div>
                            </Col>
                               
                        </Row>
                        </Container>
                </section> */}
                
                {/* <InformationComponent></InformationComponent> */}
                {/* <section id="clientSection" className="bg-primary-gradient ">
                    <Container className="py-3">
                        <Row className=" align-items-center">
                            <Col sm="12" md="6" className=" order-sm-1 order-2">
                                <img className="img-fluid" data-aos="fade-right" src={requestDisplay} alt="" />
                            </Col>
                            <Col sm="12" md="6" className="  order-1">
                                <h2 className="font-weight-bold pt-4 pb-3 text-light font-50 text-center text-md-left mt-3" >We love building meaningful and useful software products</h2>
                                <p className="font-weight-light text-light text-center text-md-left" >
                                At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. 
                                </p>
                                    <p className="pt-3 mb-5 text-center text-md-left">
                                    <Link to="/request-a-demo" className="btn btn-outline-primary btn-light" >Request for 15 Days Trail   </Link>
                                </p>
                            </Col>
                        </Row>
                    </Container>
            </section>  */}
            {/* <div className="bg-primary py-4 ctaBlock   px-0 text-white">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-sm-7">
                        <h4 className="p-0 m-0">Interested in learning more about ParkEngage? </h4>Interested in learning more about ParkEngage? Sign up for our mailing list to receive the latest news and updates
                        </div>
                        <div className="col  mt-3 mt-sm-0">
                        <div className="input-group">
  <input type="text" className="form-control customeInput" placeholder="Enter your Email Id" aria-label="Enter Your email Id" aria-describedby="button-addon2"/>
  <div className="input-group-append">
    <button className="btn  btn-light  customeButton  " type="button" id="button-addon2">Subscribe Now</button>
  </div>
</div>
                        </div>
                    </div>
                </div>
            </div> */}
            <SubscriberForm></SubscriberForm>
       

            {/* <RequestDemoSection className="bg-secondry"></RequestDemoSection> */}
            {/* <NewsList></NewsList> */}
            {/* <TestimonialsSection className="bg-secondry"></TestimonialsSection> */}
           
            </div>
            </Aux>
        )
    }

}

export default HomeComponent;