import React, { Component } from 'react';

import logo from './../../assets/img/logo.svg';
import ftPhone from './../../assets/img/ft-phone.svg';
import ftEmail from './../../assets/img/ft-email.svg';
import { Row, Col} from 'reactstrap';


class FooterAddress extends Component {

    render() {
        return (
            <Col sm="12" md="9" lg="auto" className="mb-1">
                 <img  height="70" className="image-fluid" src={logo} alt="Park Engage" srcSet=""/>
                 <p className="my-3"> 
                    <b className="pt-2 mb-2 d-block">Corporate Office:</b>
                    <span>
                    4100 Moorpark Avenue, Suite 203<br/>
                    San Jose, CA 95117<br/>
                    </span>
                 </p>
            
                {/* <Row className="mt-4 mb-2">
                    <Col className="col-auto pr-2">
                    <img  height="20" className="image-fluid" src={ftPhone} alt="Park Engage" srcSet=""/>
                    </Col>
                    <Col className="col-auto">
                    <p className="mb-2"><a href="tel:+14087271400">+1 (408) 727 1400</a></p>
                        
                    </Col>
                </Row> */}

                <Row >
                    <Col className="col-auto pr-2">
                    <img  height="20" className="image-fluid" src={ftEmail} alt="Park Engage" srcSet=""/>
                </Col>
                <Col className="col-auto">
                <p className="mb-2"><a href="mailto:info@parkengage.com">info@parkengage.com</a></p>
                <p className="mb-2"><a href="mailto:marketing@parkengage.com">marketing@parkengage.com</a></p>
                </Col>
                </Row>
                </Col>
        )
    }
}

export default FooterAddress;