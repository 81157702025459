import React, { Component } from 'react';
import Aux from './../pages/Wrapper/Auxiliary';
import { connect } from "react-redux";
import * as actionCreators from "../Redux/Actions/index";
import NotificationSystem from "react-notification-system";
import loginStyle from './login.scss';
import SimpleReactValidator from 'simple-react-validator';
import BreadCrumb from './../pages/BreadCrumb/BreadCrumb';
import $ from 'jquery';
class GenerateNewPassword extends Component {
    constructor(props){
        super(props);
        this.notificationSystem = React.createRef();
        this.validator = new SimpleReactValidator();
        
        this.state = {
            formData: {
                password: {
                    name: 'password',
                    value: '',
                    placeholder: 'Enter password',
                    validationMsg: '',
                    type: 'password',
                    id: 'pwd',
                    class: 'form-control',
                    valid: false
                },
                confirmPassword: {
                    name: 'confirmPassword',
                    value: '',
                    placeholder: 'Enter confirm password',
                    validationMsg: '',
                    type: 'password',
                    class: 'form-control',
                    id: 'confpwd',
                    valid: false
                }
            },
            formIsValid: false,
            loading: false,
            errorMsgPwd: '',
            errorMsgCPwd: '',
            passwordShown : false,
            cpasswordShown : false
        }        
    }

    componentWillMount(){
       
    }

    inputHandler = (event)=> {
        const { name, value } = event.target;
        let prevStateData = this.state.formData
        const { errorMsgPwd, errorMsgCPwd } = this.state
          prevStateData[name].value  = value;
         this.setState( { formData: prevStateData , errorMsgPwd : '', errorMsgCPwd: ''} )
     }

     togglePasswordVisiblity = (e, key) => {
        if (key === 'password') {
            this.setState({passwordShown : this.state.passwordShown ? false : true})
        }
        if(key === 'cpassword'){
            this.setState({cpasswordShown : this.state.cpasswordShown ? false : true})

        }
          
    }
    
    submitHandler = (event)=> {
        event.preventDefault();
        //let passw=  /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/;
        let passw = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,15}$/;
        const notification = this.notificationSystem.current;
        if (this.validator.allValid()) {
        const formData1 = {};
        const {errorMsgPwd , errorMsgCPwd} = this.state;
        for (let formElement in this.state.formData) {
            formData1[formElement] = this.state.formData[formElement].value;
        }
        if(!( passw.test(formData1.password) )){ 
            let errorMsgPwd  = 'Entered Password does not match with the Password policy';                 
            this.setState({errorMsgPwd: errorMsgPwd})
        } else {
            if(formData1.password != formData1.confirmPassword ){ 
                let errorMsgCPwd  = 'Confirm Password should be same as password';
                this.setState({errorMsgCPwd: errorMsgCPwd})
            } else{
            this.setState({ loading: true });
            formData1['token'] =  this.props.match.params.token;
             this.props.GeneratePasswordRequestHandler(formData1).then(() => {
                if(this.props.result.status ==201){
                    let res = this.props.result.data
                      notification.addNotification({
                          title: 'Successfully!',
                          message: 'Password has been updated Successfully',
                          level: 'success'
                      });
                      setTimeout(()=>{ this.props.history.push('/'); }, 1000);
                      $("#myModal").modal("show"); 
                }
                else{
                     notification.addNotification({
                         title: '"'+this.props.result.data.errors.message+'"',
                         message: '"'+this.props.result.data.errors.detail.token[0]+'"',
                         level: 'error'
                     });
                     setTimeout(()=>{ this.props.history.push('/'); }, 3000);
                }
                this.setState({ loading: false });
             })

            }
        }

        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }


    render() {
        let Field = this.state.formData;
        const { errorMsgPwd, errorMsgCPwd } = this.state
        return(
            <Aux>
            <NotificationSystem ref={this.notificationSystem} />
            <BreadCrumb
                headerTitle={`Create Password`}
                headerName={"Create Password"}
                headerContent={
                    ""
                }
            />
                <section id="content-section">
                <div className="container">
                <div className="login-popup">
                <div className="container">
                    <div className="mt-4 mb-5 p-0">
                    <div className="row justify-content-center">                    
                    <div className="col-sm-7">
                        <div className="login-rt border px-5 py-5">
                            <div className="alert alert-primary" role="alert">
                            Password should 8 to 15 characters, at least one uppercase letter, one lowercase letter, one number and one special character.
                            </div>
                        <form name="LoginForm" onSubmit={this.submitHandler}>
                            <div className="form-group">
                                <label>Enter Password</label>
                                <div className="d-flex myiconset">
                                <input type={this.state.passwordShown ? 'text' : Field.password.type} name={Field.password.name} onChange={this.inputHandler} onBlur={this.inputHandler} value={Field.password.value}  className={Field.password.class} placeholder={Field.password.placeholder} id={Field.password.id} />
                                <i className={this.state.passwordShown ? 'fas fa-eye-slash margin-right-top' : 'fas fa-eye margin-right-top'} onClick={(e) => this.togglePasswordVisiblity(e, 'password')}></i>
                                </div>
                                { errorMsgPwd  && 
                                    <p className="input-errors">{errorMsgPwd}</p>
                                }
                                {!errorMsgPwd &&
                                 this.validator.message('Password' , Field.password.value, 'required')}
                            </div>
                            <div className="form-group">
                                <label>Enter Confirm Password</label>
                                <div className="d-flex myiconset">
                                <input type={this.state.cpasswordShown ? 'text' : Field.confirmPassword.type} name={Field.confirmPassword.name}  onChange={this.inputHandler} onBlur={this.inputHandler} value={Field.confirmPassword.value} className={Field.confirmPassword.class} placeholder={Field.confirmPassword.placeholder} id={Field.confirmPassword.id} />
                                <i className={this.state.cpasswordShown ? 'fas fa-eye-slash margin-right-top' : 'fas fa-eye margin-right-top'} onClick={(e) => this.togglePasswordVisiblity(e, 'cpassword')}></i>
                                </div>
                                { errorMsgCPwd  && 
                                    <p className="input-errors">{errorMsgCPwd}</p>
                                }
                                {!errorMsgCPwd &&
                                    this.validator.message('Confirm Password' , Field.confirmPassword.value, 'required')}
                            </div>
                            {!this.state.loading && 
                            <button type="submit" className="btn btn-primary btn-outline-primary">Create Password</button>
                            }
                            { this.state.loading && 
                            <button type="button" disabled className="btn btn-primary btn-outline-primary">Create Password</button>
                            }
                        </form>
                         </div>
                    </div>
                    </div>
                    </div>
                </div>
                </div>
                    </div>
                </section>

            </Aux>
        )
    }

}


const mapStateToProps = state => {
    return {
        isFetching: state.genetatePasswordRequest,
        result: state.genetatePasswordRequest.result
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        GeneratePasswordRequestHandler: (data) => dispatch(actionCreators.GeneratePasswordRequest(data)),
    }
}

 export default connect( mapStateToProps, mapDispatchToProps )(GenerateNewPassword)
